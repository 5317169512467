var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slideQuickActionsContainer"},[(_vm.showBpToggleSwitch)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
      content: ("" + (_vm.getTooltipContentForBestPractisesToggle())),
      placement: 'bottom',
    }),expression:"{\n      content: `${getTooltipContentForBestPractisesToggle()}`,\n      placement: 'bottom',\n    }"}],staticClass:"bestPracticesSwitch",attrs:{"data-pendo-id":"slide-best-practice-toggle"}},[(_vm.isSuggestionsLoaded || _vm.shouldToggleDisable)?_c('v-switch',{attrs:{"label":_vm.$t('slideDetails.bestPractices'),"hide-details":"","disabled":true}}):_c('v-switch',{attrs:{"label":_vm.$t('slideDetails.bestPractices'),"color":"#21a7e0","hide-details":"","disabled":_vm.getBestPractisesOnSlide.filter(function (item) { return item.isAdded; }).length <= 0 ||
        _vm.disableActions},on:{"click":_vm.handleToggleButton},model:{value:(_vm.toggleBestPracticesSwitch),callback:function ($$v) {_vm.toggleBestPracticesSwitch=$$v},expression:"toggleBestPracticesSwitch"}})],1):_vm._e(),(!_vm.getReplaceStackData.convertedImage)?_c('div',{staticClass:"downloadSlide"},[(_vm.downloadingSlide)?_c('v-progress-circular',{attrs:{"size":"22","width":3,"indeterminate":""}}):_c('Download',{staticClass:"pl-1",attrs:{"mode":'icon',"file-name":_vm.getFilename(_vm.result),"item":_vm.result,"log-download":_vm.logDownload,"count-first-time":1,"on-download":_vm.handleSlideDownload,"origin":"slide","limitsKey":"slide","speedDialPosTop":'-140',"speedDialPosRight":'0',"customSpeedDialPositionEnabled":true,"isDisabled":_vm.getShowLoadingOverlay || _vm.isDownloadBlocked,"downloadBlocked":_vm.isDownloadBlocked,"data-pendo-id":"slide-quick-action-download"}})],1):(_vm.getSidePanelAction === 'replaceVisuals')?_c('div',{staticClass:"downloadSlide"},[(_vm.downloadingSlideInReplaceVisuals)?_c('v-progress-circular',{attrs:{"size":"22","width":3,"indeterminate":""}}):_c('Download',{attrs:{"mode":'icon',"origin":"slide","limitsKey":"slide","file-name":((_vm.uuidv4()) + ".pptx"),"item":_vm.result,"count-first-time":1,"on-download":_vm.downloadLocalForReplaceVisuals,"downloadUrl":_vm.getReplaceStackData.downloadUrl,"log-download":_vm.logDownloadForReplaceVisuals,"downloadType":_vm.getReplaceStackData.isImageReplacementComplete
          ? 'SlideReplacement'
          : '',"speedDialPosTop":'-140',"speedDialPosRight":'0',"customSpeedDialPositionEnabled":true,"downloadIconType":_vm.getReplaceStackData.isIconReplacementComplete
          ? 'SlideIconReplacement'
          : '',"isDisabled":!_vm.getReplaceStackData.convertedImage ||
        _vm.getShowLoadingOverlay ||
        _vm.isDownloadBlocked,"downloadBlocked":_vm.isDownloadBlocked,"data-pendo-id":"slide-quick-action-download"}})],1):_vm._e(),_c('v-btn',{attrs:{"text":"","loading":_vm.processingFavSlide,"ripple":false,"disabled":!!_vm.getReplaceStackData.convertedImage || _vm.disableActions || _vm.isTcCompleted,"data-pendo-id":"slide-quick-action-fav"},on:{"click":function($event){return _vm.handleFavorite({ asset: _vm.result })}}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":("" + (_vm.isFav ? '#21a7e0' : 'black'))}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(("mdi-heart" + (!_vm.isFav ? '-outline' : '')))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(("" + (!_vm.isFav ? _vm.$t('slideDetails.addFavorites') : _vm.$t('slideDetails.removeFavorites')))))])])],1),(_vm.getSidePanelAction === 'replaceVisuals' && !_vm.isBPLSlideView)?_c('v-btn',{attrs:{"ripple":false,"text":"","loading":_vm.processingCollectionForReplaceVisuals,"disabled":_vm.getShowLoadingOverlay},on:{"mouseenter":_vm.trackCollectionMaxLimitEvent,"click":function($event){return _vm.handleCollectionsForReplaceVisuals()}}},[(
        !_vm.getReplaceStackData.isAddedToCollection && _vm.isCollectionLimitReached
      )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#bdbdbd"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-plus-outline')+" ")])]}}],null,false,3293801373)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.addToCollectionTooltip')))])]):_vm._e(),(
        !_vm.getReplaceStackData.isAddedToCollection && !_vm.isCollectionLimitReached
      )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-plus-outline')+" ")])]}}],null,false,163399267)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.addToCollection')))])]):_vm._e(),(_vm.getReplaceStackData.isAddedToCollection)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#21a7e0"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-minus')+" ")])]}}],null,false,2103942784)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.removeFromCollection')))])]):_vm._e()],1):(_vm.getSidePanelAction === 'anonymizeSlide' && _vm.disableActions)?_c('v-btn',{attrs:{"ripple":false,"text":"","loading":_vm.processingCollectionForSlideAnonymize,"disabled":_vm.getShowLoadingOverlay},on:{"mouseenter":_vm.trackCollectionMaxLimitEvent,"click":function($event){return _vm.handleCollectionForSlideAnonymize()}}},[(
        !_vm.getAnonymizedData.isAddedToCollection && _vm.isCollectionLimitReached
      )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#bdbdbd"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-plus-outline')+" ")])]}}],null,false,3293801373)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.addToCollectionTooltip')))])]):_vm._e(),(
        !_vm.getAnonymizedData.isAddedToCollection && !_vm.isCollectionLimitReached
      )?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-plus-outline')+" ")])]}}],null,false,163399267)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.addToCollection')))])]):_vm._e(),(_vm.getAnonymizedData.isAddedToCollection)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#21a7e0"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-minus')+" ")])]}}],null,false,2103942784)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.removeFromCollection')))])]):_vm._e()],1):(!_vm.isBPLSlideView && !_vm.disableActions)?_c('v-btn',{attrs:{"ripple":false,"text":"","loading":_vm.processingCollection,"data-pendo-id":"slide-quick-action-collection"},on:{"mouseenter":_vm.trackCollectionMaxLimitEvent,"click":function($event){return _vm.handleCollection()}}},[(!_vm.isAddedToCollection && _vm.isCollectionLimitReached)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#bdbdbd"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-plus-outline')+" ")])]}}],null,false,3293801373)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.addToCollectionTooltip')))])]):_vm._e(),(!_vm.isAddedToCollection && !_vm.isCollectionLimitReached)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-plus-outline')+" ")])]}}],null,false,163399267)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.addToCollection')))])]):_vm._e(),(_vm.isAddedToCollection)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"200","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"#21a7e0"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-file-minus')+" ")])]}}],null,false,2103942784)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.removeFromCollection')))])]):_vm._e()],1):_vm._e(),(!_vm.hideShareIcon)?_c('v-btn',{attrs:{"text":"","ripple":false,"disabled":!!_vm.getReplaceStackData.convertedImage || _vm.disableActions || _vm.isTcCompleted},on:{"click":_vm.shareSlide}},[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s('mdi-account-plus-outline'))])]}}],null,false,1043974980)},[_c('span',[_vm._v(_vm._s(_vm.$t('slideDetails.share')))])])],1):_vm._e(),(!_vm.hideCopyLink)?_c('div',{class:_vm.getReplaceStackData.convertedImage || _vm.disableActions || _vm.isTcCompleted
        ? 'copy-link-disabled'
        : 'copy-link',attrs:{"data-pendo-id":"slide-quick-action-copy-link"},on:{"click":_vm.copyLink}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"copyLink"},on),[_c('div',{staticClass:"copyIconAndTitle"},[_c('div',{staticClass:"icon"},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s('mdi-link-variant'))])],1)]),(_vm.showCopied === true)?_c('div',{staticClass:"copiedStatus bg-copied-text"},[_c('v-icon',{attrs:{"small":"","color":"#21a7e0"}},[_vm._v(_vm._s('mdi-check'))]),_c('div',[_vm._v(_vm._s(_vm.$t('slideDetails.copiedText')))])],1):_vm._e()])]}}],null,false,1504896148)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('slideDetails.copyLink'))+" ")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }