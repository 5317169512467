<template>
  <div :class="`brand-images-library-wrapper ${origin.toLowerCase()}`">
    <!-- <v-container v-if="isLoading">
      <ImagesPlaceHolder />
    </v-container> -->
    <div class="error-wrapper" v-if="!images.length">
      <EmptyState
        :img-url="'/assets/img/generate/empty-extracted-img.png'"
        :is-btn-visible="origin.toLowerCase() === 'generate'"
        :buttonName="'Add context'"
        :isBtnOutlined="false"
        :handleCTA="addContext"
        :origin="origin"
      >
        <template v-slot:description>
          <div>
            <!-- <p class="error-description">{{ $t(error.message) }}</p> -->
            <p class="error-description">{{ getEmptyErrorStateText }}</p>
          </div>
        </template>
      </EmptyState>
    </div>
    <div v-else :class="`images`" ref="companyImages">
      <template>
        <masonry :gutter="10" :cols="2">
          <v-img
            v-for="image in images"
            :key="image.id"
            :src="image.url"
            lazy-src="/assets/img/slides/placeholder-slide.svg"
            class="grey lighten-2"
            contain
            @click="onImageClick(image)"
            :class="`${
              image.id === selectedImage ? 'selected-image' : ''
            } library-image`"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </masonry>
        <!-- <clip-loader
            v-if="moreImagesLoading && !allResultsLoaded"
            :color="`#21a7e0`"
            :width="'24'"
            :height="'24'"
            :size="'24px'"
            class="cliploader"
          /> -->
      </template>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import EmptyState from '../../common/EmptyState.vue';
// import ImagesPlaceHolder from './ImagesPlaceHolder.vue';

export default {
  name: 'ExtractedImages',
  props: {
    images: {
      type: Array,
      default: () => [],
      required: false,
    },
    origin: {
      type: String,
      default: '',
    },
    noOfSlides: {
      type: Number,
      default: 0,
    },
  },
  components: {
    EmptyState,
    // ImagesPlaceHolder,
  },
  data() {
    return {
      selectedImage: '',
    };
  },
  computed: {
    // Computed property to get empty images error text
    getEmptyErrorStateText() {
      if (this.origin.toLowerCase() === 'redesign') {
        return this.noOfSlides > 1
          ? this.$t('redesign.manageSettings.redesignNoImgInSlides')
          : this.$t('redesign.manageSettings.redesignNoImgInSlide');
      }
      return `Let's add more context to find amazing visuals.`;
    },
  },
  methods: {
    ...mapActions('prezentationDetails', ['setSelectedSlideAction']),
    addContext() {
      console.log('context');
      if (this.origin === 'prezentations') {
        this.setSelectedSlideAction({
          id: 'addSlideContext',
          labelText: 'Add slide context',
          tooltipText: 'Add slide context',
          icon: 'mdi-creation',
          isCustomIcon: false,
          pendoId: 'slide-action-add-slide-context',
        });
      } else {
        this.$emit('changePanel', 'MagicFill');
      }
    },
    async onImageClick(image) {
      this.selectedImage = image.id;
      //   EventBus.$emit(IMAGE_UPLOADING);
      try {
        this.$emit('imageSelected', {
          ...image,
          origin: 'Extracted',
          s3_path: image.s3_path,
          url: image.url,
          source: 'extracted',
        });
        // EventBus.$emit(BRAND_IMAGE_SELECTED);
      } catch (e) {
        console.log('uploadAdobeImage error: ', e);
        // EventBus.$emit(IMAGE_UPLOAD_FAILED);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.brand-images-library-wrapper {
  height: 456px;

  .empty-state__container {
    margin: unset;
    padding: 3rem;
  }

  .error-description {
    color: #000;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: normal;
    letter-spacing: normal;
    /** trying to remove the padding as shown in the below class -> .no-prez-description but not working as expected hence negative margin here*/
    margin-top: -10px;
    text-align: center;
  }
  .no-prez-description {
    padding-top: 0px;
  }

  .images,
  .error-wrapper {
    max-height: 392px;
    overflow-y: auto;
    padding-right: 12px;
  }

  .images {
    padding: 12px 12px 0px 0px;
  }

  .images.no-search {
    max-height: 464px;
  }

  .library-image {
    &:hover {
      box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%),
        0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important;
      cursor: pointer;
    }
    background-color: #e0e0e0 !important;
    border-color: #e0e0e0 !important;
    border-radius: 8px;
    box-shadow: $item-box-shadow;
    margin-bottom: 10px;
  }

  .selected-image {
    border: 3px solid #21a7e0 !important;
    box-shadow: 2px 0px 4px rgba(216, 216, 216, 0.5),
      0px 2px 4px rgba(216, 216, 216, 0.5);
  }
}

.generate,
.redesign {
  height: auto;
  .images,
  .error-wrapper {
    max-height: calc(100vh - 390px);
  }
}
</style>
