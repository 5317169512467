<template>
  <div class="acc-contact-wrapper">
    <v-dialog width="70%" v-model="dialog">
      <template v-slot:activator="{ on, attrs }">
        <div class="acc-contact-btn">
          <v-btn
            class="primary-button"
            color="#21a7e0"
            rounded
            height="48"
            style="text-transform: none !important"
            v-bind="attrs"
            v-on="on"
            @click="onClickGetInTouch"
          >
            {{ $t('zenithWorkshopSteps.stepOneTitle') }}
          </v-btn>
        </div>
      </template>

      <v-card class="acc-contact-cntr" v-if="dialog && !dialogSuccess">
        <div class="acc-contact-header">
          <h2>{{ $t('zenithWorkshopSteps.stepOneTitle') }}</h2>

          <v-icon medium class="notificationMenuCloseIcon" @click="handleClose">
            mdi-close
          </v-icon>
        </div>

        <div class="acc-offerings-cntr">
          <h5>{{ $t('zenithWorkshop.getInTouchQuery') }}</h5>
          <h2 class="offer-sub-title">Standard</h2>
          <ul>
            <li
              v-for="(standard, index) in standardOfferings"
              :key="index + '_standarOfferings'"
            >
              <v-checkbox
                row
                v-model="standard.checked"
                :data-pendo-id="standard.title"
                @change="handleOfferType"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="header">{{ $t(standard.title) }}</div>
                  </div>
                </template>
              </v-checkbox>
            </li>
          </ul>

          <h2 class="mt-6 offer-sub-title">Master Class</h2>
          <ul>
            <li
              v-for="(master, index) in masterClassOfferings"
              :key="index + '_standarOfferings'"
            >
              <v-checkbox
                row
                v-model="master.checked"
                :data-pendo-id="master.title"
                @change="handleOfferType"
              >
                <template #label>
                  <div class="label-wrapper">
                    <div class="header">{{ $t(master.title) }}</div>
                  </div>
                </template>
              </v-checkbox>
            </li>
          </ul>

          <h5>{{ $t('zenithWorkshop.provideDetails') }}</h5>
          <textarea
            :placeholder="$t('zenithWorkshop.tellUsMore')"
            v-model="offerDetails"
          ></textarea>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="acc-contact-submit"
            :disabled="
              (!standardOfferType.length && !masterOfferType.length) ||
              offerDetails.length < 10
            "
            @click="handleSubmit()"
          >
            {{ $t('common.submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="acc-msg-cntr acc-success-cntr" v-if="dialogSuccess">
        <div class="acc-msg-header">
          <v-icon medium class="notificationMenuCloseIcon" @click="handleClose">
            mdi-close
          </v-icon>
        </div>

        <v-img
          class="acc-succ-img"
          src="/assets/img/accelerators/get_in_touch.svg"
        />

        <div class="acc-succ-text">
          <p>{{ $t('accelerators.thanksForSubmittingRequest') }}</p>
          <p>{{ $t('accelerators.ourTeamGetBackToYou') }}</p>
        </div>
      </v-card>

      <div class="acc-loader acc-msg-cntr" v-if="loader">
        <LoadSpinner />
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import serviceOperationsApi from '../../../API/service-operations';
import LoadSpinner from '../../common/LoadSpinner';
import { trackAcceleratorsEvents } from '../../common/Analytics/AcceleratorsEvents';
import {
  TD_PLACEMENT,
  TD_SELECTED_ACCELERATOR_TYPE,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_PREZENTATION_COMPANY_SOURCE,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ZenithWorkshopContactUs',
  props: {
    placement: {
      type: String,
      default: '',
    },
  },
  created() {
    this.reset();
  },
  components: { LoadSpinner },
  data() {
    return {
      dialog: false,
      dialogSuccess: false,
      loader: false,
      standardOfferings: [
        {
          title: 'Problem Solving',
        },
        {
          title: 'Story-Lining',
        },
        {
          title: 'Creating Presentations',
        },
        {
          title: 'Delivering Presentations',
        },
      ],
      masterClassOfferings: [
        {
          title: 'Master Class 1',
        },
        {
          title: 'Master Class 2',
        },
      ],
      standardOfferType: [],
      masterOfferType: [],
      offerDetails: '',
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        this.reset();
      }
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    reset() {
      this.standardOfferings = [
        {
          title: 'Problem-Solving',
        },
        {
          title: 'Storylining',
        },
        {
          title: 'Creating Presentations',
        },
        {
          title: 'Delivering Presentations',
        },
      ];
      this.masterClassOfferings = [
        {
          title: 'Master Class 1',
        },
        {
          title: 'Master Class 2',
        },
      ];
      this.standardOfferType = [];
      this.masterOfferType = [];
      this.offerDetails = '';
    },
    handleClose() {
      this.dialog = false;
      this.dialogSuccess = false;
      this.standardOfferType = [];
      this.masterOfferType = [];
      this.offerDetails = '';
    },
    onClickGetInTouch() {
      const otherData = {
        [TD_PLACEMENT]: this.placement,
      };
      trackAcceleratorsEvents.acceleratorsGetInTouchClick(
        this.currentUser,
        otherData,
      );
    },
    async handleOfferType() {
      this.standardOfferType = this.standardOfferings.filter(
        (offer) => offer.checked,
      );
      this.masterOfferType = this.masterClassOfferings.filter(
        (offer) => offer.checked,
      );
    },
    async handleSubmit() {
      this.loader = true;
      const workshopType = [];
      const workshopNames = [];

      if (this.standardOfferType.length) {
        workshopType.push('Standard');
        this.standardOfferType.map((offer) => workshopNames.push(offer.title));
      }
      if (this.masterOfferType.length) {
        workshopType.push('Master Class');
        this.masterOfferType.map((offer) => workshopNames.push(offer.title));
      }

      const body = {
        email: this.currentUser.user.id,
        serviceRequestType: JSON.stringify({
          workshopType,
          workshopNames,
        }),
        additionalDetails: this.offerDetails,
        source: 'zenith-workshop',
      };

      const response = await serviceOperationsApi.methods.submitServiceRequest(
        body,
      );

      if (response) {
        this.loader = false;
        this.dialogSuccess = true;
      }

      const otherData = {
        [TD_PLACEMENT]: this.placement,
        [TD_COMMON_COLUMN_NAME]: TD_SELECTED_ACCELERATOR_TYPE,
        [TD_COMMON_COLUMN_VALUE]: `${this.standardOfferType}, ${this.masterOfferType}`,
        [TD_PREZENTATION_COMPANY_SOURCE]: this.offerDetails,
      };
      trackAcceleratorsEvents.acceleratorsSubmitClick(
        this.currentUser,
        otherData,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.acc-contact-wrapper {
  ::v-deep .v-card {
    height: 80vh;
    overflow-y: scroll;
  }
}
.acc-contact-cntr {
  padding: 1rem;
  border-radius: 12px !important;
  .acc-contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h2 {
      flex: 1;
    }
  }

  .acc-offerings-cntr {
    h5 {
      margin: 1rem 0;
      font-size: 1rem;
      font-weight: 600;
    }

    .offer-sub-title {
      width: 100%;
      margin-bottom: 1.5rem;
      color: #21a7e0;
      font-size: 24px;
      font-weight: 600;
    }

    ul {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 1rem;

      li {
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 1rem;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 1rem;

        .v-input--selection-controls {
          margin: 0;
          padding: 0;
        }

        ::v-deep .v-label {
          left: 0.25rem !important;
          color: black;
        }

        ::v-deep .v-messages {
          min-height: 0;
        }

        ::v-deep .v-radio {
          margin-right: 0;
        }

        ::v-deep .v-input__slot {
          margin-bottom: 0;
        }
      }
    }

    textarea {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
        -1px -1px 4px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      padding: 1rem;
      width: 100%;
      min-height: 150px;
      font-size: 1rem;
    }
  }

  ::v-deep .v-card__actions {
    padding: 1rem 0 0 0;
  }

  .acc-contact-submit {
    background-color: #21a7e0;
    letter-spacing: normal;
    color: white;
    font-weight: 700;
    font-size: 1rem;
    text-transform: capitalize;
    border-radius: 25px;
    width: 100px;
  }
}

.acc-contact-btn {
  display: grid;
  place-items: center;
  margin: 1rem 0;

  button {
    font-family: Lato;
    font-size: 1.1rem;
    font-weight: 600;
    color: white;
    letter-spacing: normal;
  }
}

.acc-loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background-color: #00000038;
  backdrop-filter: blur(2px);
}

.acc-msg-cntr {
  border-radius: 12px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;

  h2 {
    margin-bottom: 0.5rem;
  }
}

.acc-success-cntr {
  .acc-succ-img {
    width: 55%;

    ::v-deep .v-image__image--cover {
      background-size: 100%;
    }
  }
}

.acc-msg-header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.acc-succ-text {
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 3rem;
}
</style>
