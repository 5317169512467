<template>
  <div class="slideQuickActionsContainer">
    <!-- best practices switch !-->
    <div
      class="bestPracticesSwitch"
      v-if="showBpToggleSwitch"
      data-pendo-id="slide-best-practice-toggle"
      v-tooltip="{
        content: `${getTooltipContentForBestPractisesToggle()}`,
        placement: 'bottom',
      }"
    >
      <v-switch
        v-if="isSuggestionsLoaded || shouldToggleDisable"
        :label="$t('slideDetails.bestPractices')"
        hide-details
        :disabled="true"
      ></v-switch>
      <v-switch
        v-else
        v-model="toggleBestPracticesSwitch"
        :label="$t('slideDetails.bestPractices')"
        color="#21a7e0"
        hide-details
        :disabled="
          getBestPractisesOnSlide.filter((item) => item.isAdded).length <= 0 ||
          disableActions
        "
        @click="handleToggleButton"
      />
    </div>

    <!-- download !-->
    <!-- download before replace visual -->
    <div class="downloadSlide" v-if="!getReplaceStackData.convertedImage">
      <v-progress-circular
        size="22"
        :width="3"
        indeterminate
        v-if="downloadingSlide"
      />
      <Download
        v-else
        class="pl-1"
        :mode="'icon'"
        :file-name="getFilename(result)"
        :item="result"
        :log-download="logDownload"
        :count-first-time="1"
        :on-download="handleSlideDownload"
        origin="slide"
        limitsKey="slide"
        :speedDialPosTop="'-140'"
        :speedDialPosRight="'0'"
        :customSpeedDialPositionEnabled="true"
        :isDisabled="getShowLoadingOverlay || isDownloadBlocked"
        :downloadBlocked="isDownloadBlocked"
        data-pendo-id="slide-quick-action-download"
      />
    </div>
    <div
      class="downloadSlide"
      v-else-if="getSidePanelAction === 'replaceVisuals'"
    >
      <v-progress-circular
        size="22"
        :width="3"
        indeterminate
        v-if="downloadingSlideInReplaceVisuals"
      />
      <Download
        v-else
        :mode="'icon'"
        origin="slide"
        limitsKey="slide"
        :file-name="`${uuidv4()}.pptx`"
        :item="result"
        :count-first-time="1"
        :on-download="downloadLocalForReplaceVisuals"
        :downloadUrl="getReplaceStackData.downloadUrl"
        :log-download="logDownloadForReplaceVisuals"
        :downloadType="
          getReplaceStackData.isImageReplacementComplete
            ? 'SlideReplacement'
            : ''
        "
        :speedDialPosTop="'-140'"
        :speedDialPosRight="'0'"
        :customSpeedDialPositionEnabled="true"
        :downloadIconType="
          getReplaceStackData.isIconReplacementComplete
            ? 'SlideIconReplacement'
            : ''
        "
        :isDisabled="
          !getReplaceStackData.convertedImage ||
          getShowLoadingOverlay ||
          isDownloadBlocked
        "
        :downloadBlocked="isDownloadBlocked"
        data-pendo-id="slide-quick-action-download"
      />
    </div>
    <!--
    <div class="downloadSlide" v-else>
      <v-progress-circular
        size="22"
        :width="3"
        indeterminate
        v-if="downloadingSlide"
      />
      <Download
        v-else
        class="pl-1"
        :mode="'icon'"
        :file-name="getFilename(result)"
        :item="result"
        :log-download="logDownload"
        :count-first-time="1"
        :on-download="handleSlideDownload"
        origin="slide"
        limitsKey="slide"
        :speedDialPosTop="'-140'"
        :speedDialPosRight="'0'"
        :customSpeedDialPositionEnabled="true"
        data-pendo-id="slide-quick-action-download"
      />
    </div>
    !-->

    <!-- Favorites !-->
    <v-btn
      text
      @click="handleFavorite({ asset: result })"
      :loading="processingFavSlide"
      :ripple="false"
      :disabled="
        !!getReplaceStackData.convertedImage || disableActions || isTcCompleted
      "
      data-pendo-id="slide-quick-action-fav"
    >
      <v-tooltip
        bottom
        max-width="250"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            :color="`${isFav ? '#21a7e0' : 'black'}`"
            v-bind="attrs"
            v-on="on"
          >
            {{ `mdi-heart${!isFav ? '-outline' : ''}` }}
          </v-icon>
        </template>
        <span>{{
          `${
            !isFav
              ? $t('slideDetails.addFavorites')
              : $t('slideDetails.removeFavorites')
          }`
        }}</span>
      </v-tooltip>
    </v-btn>

    <!-- Collections !-->
    <v-btn
      :ripple="false"
      text
      @mouseenter="trackCollectionMaxLimitEvent"
      @click="handleCollectionsForReplaceVisuals()"
      :loading="processingCollectionForReplaceVisuals"
      v-if="getSidePanelAction === 'replaceVisuals' && !isBPLSlideView"
      :disabled="getShowLoadingOverlay"
    >
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="
          !getReplaceStackData.isAddedToCollection && isCollectionLimitReached
        "
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="#bdbdbd" v-bind="attrs" v-on="on">
            {{ 'mdi-file-plus-outline' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.addToCollectionTooltip') }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="
          !getReplaceStackData.isAddedToCollection && !isCollectionLimitReached
        "
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">
            {{ 'mdi-file-plus-outline' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.addToCollection') }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="getReplaceStackData.isAddedToCollection"
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="#21a7e0" v-bind="attrs" v-on="on">
            {{ 'mdi-file-minus' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.removeFromCollection') }}</span>
      </v-tooltip>
    </v-btn>
    <!-- Add to collection button for after anonymization of slide -->
    <v-btn
      :ripple="false"
      text
      @mouseenter="trackCollectionMaxLimitEvent"
      @click="handleCollectionForSlideAnonymize()"
      :loading="processingCollectionForSlideAnonymize"
      v-else-if="getSidePanelAction === 'anonymizeSlide' && disableActions"
      :disabled="getShowLoadingOverlay"
    >
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="
          !getAnonymizedData.isAddedToCollection && isCollectionLimitReached
        "
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="#bdbdbd" v-bind="attrs" v-on="on">
            {{ 'mdi-file-plus-outline' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.addToCollectionTooltip') }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="
          !getAnonymizedData.isAddedToCollection && !isCollectionLimitReached
        "
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">
            {{ 'mdi-file-plus-outline' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.addToCollection') }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="getAnonymizedData.isAddedToCollection"
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="#21a7e0" v-bind="attrs" v-on="on">
            {{ 'mdi-file-minus' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.removeFromCollection') }}</span>
      </v-tooltip>
    </v-btn>
    <v-btn
      :ripple="false"
      text
      @mouseenter="trackCollectionMaxLimitEvent"
      @click="handleCollection()"
      :loading="processingCollection"
      v-else-if="!isBPLSlideView && !disableActions"
      data-pendo-id="slide-quick-action-collection"
    >
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="!isAddedToCollection && isCollectionLimitReached"
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="#bdbdbd" v-bind="attrs" v-on="on">
            {{ 'mdi-file-plus-outline' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.addToCollectionTooltip') }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="!isAddedToCollection && !isCollectionLimitReached"
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">
            {{ 'mdi-file-plus-outline' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.addToCollection') }}</span>
      </v-tooltip>
      <v-tooltip
        bottom
        max-width="200"
        fixed
        v-if="isAddedToCollection"
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="#21a7e0" v-bind="attrs" v-on="on">
            {{ 'mdi-file-minus' }}
          </v-icon>
        </template>
        <span>{{ $t('slideDetails.removeFromCollection') }}</span>
      </v-tooltip>
    </v-btn>

    <!-- Share !-->
    <v-btn
      text
      @click="shareSlide"
      :ripple="false"
      v-if="!hideShareIcon"
      :disabled="
        !!getReplaceStackData.convertedImage || disableActions || isTcCompleted
      "
    >
      <v-tooltip
        bottom
        max-width="250"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on">{{
            'mdi-account-plus-outline'
          }}</v-icon>
        </template>
        <span>{{ $t('slideDetails.share') }}</span>
      </v-tooltip>
    </v-btn>

    <!-- Copy link !-->
    <div
      :class="
        getReplaceStackData.convertedImage || disableActions || isTcCompleted
          ? 'copy-link-disabled'
          : 'copy-link'
      "
      @click="copyLink"
      v-if="!hideCopyLink"
      data-pendo-id="slide-quick-action-copy-link"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <div v-on="on" class="copyLink">
            <div class="copyIconAndTitle">
              <div class="icon">
                <v-icon color="black">{{ 'mdi-link-variant' }}</v-icon>
              </div>
            </div>
            <div v-if="showCopied === true" class="copiedStatus bg-copied-text">
              <v-icon small color="#21a7e0">{{ 'mdi-check' }}</v-icon>
              <div>{{ $t('slideDetails.copiedText') }}</div>
            </div>
          </div>
        </template>
        <span>
          {{ $t('slideDetails.copyLink') }}
        </span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapGetters, mapActions, mapState } from 'vuex';
import { getFilename } from '../../../../utils/common';
import Download from '@/components/common/Download';

export default {
  name: 'SlideQuickActions',
  data() {
    return {
      disableActions: false,
    };
  },
  components: {
    Download,
  },
  watch: {
    isSlideDetailsDirty(val) {
      if (val && this.getSidePanelAction === 'anonymizeSlide') {
        this.disableActions = true;
      } else {
        this.disableActions = false;
      }
    },
  },
  props: {
    slideType: {
      type: String,
      default: '',
    },
    isFav: {
      type: Boolean,
      default: false,
    },
    isCollectionLimitReached: {
      type: Boolean,
      default: false,
    },
    processingCollection: {
      type: Boolean,
      default: false,
    },
    trackCollectionMaxLimitEvent: {
      type: Function,
      default: () => {},
    },
    isAddedToCollection: {
      type: Boolean,
      default: false,
    },
    processingFavSlide: {
      type: Boolean,
      default: false,
    },
    handleFavorite: {
      type: Function,
      default: () => {},
    },
    result: {
      type: Object,
      default: () => ({}),
    },
    downloadingSlide: {
      type: Boolean,
      default: false,
    },
    handleToggleButton: {
      type: Function,
      default: () => {},
    },
    handleSlideDownload: {
      type: Function,
      default: () => {},
    },
    logDownload: {
      type: Function,
      default: () => {},
    },
    handleCollection: {
      type: Function,
      default: () => {},
    },
    shareSlide: {
      type: Function,
      default: () => {},
    },
    copyLink: {
      type: Function,
      default: () => {},
    },
    showCopied: {
      type: Boolean,
      default: false,
    },
    downloadLocalForReplaceVisuals: {
      type: Function,
      default: () => {},
    },
    logDownloadForReplaceVisuals: {
      type: Function,
      default: () => {},
    },
    downloadingSlideInReplaceVisuals: {
      type: Boolean,
      default: false,
    },
    handleCollectionsForReplaceVisuals: {
      type: Function,
      default: () => {},
    },
    processingCollectionForReplaceVisuals: {
      type: Boolean,
      default: false,
    },
    handleCollectionForSlideAnonymize: {
      type: Function,
      default: () => {},
    },
    processingCollectionForSlideAnonymize: {
      type: Boolean,
      default: false,
    },
  },
  // watch: {
  //   toggleBestPractises(val) {
  //     this.setToggleBestPracticesSwitch(val);
  //   },
  // },
  computed: {
    ...mapState('prezentationDetails', [
      'complyDetails',
      'actionCompletedInfo',
    ]),
    ...mapState('slidesStore', ['isSlideDetailsDirty']),
    ...mapGetters('slidesStore', [
      'getBestPractisesOnSlide',
      'getSidePanelAction',
      'getReplaceStackData',
      'getToggleBestPracticesSwitch',
      'getShowLoadingOverlay',
      'getSidePanelAction',
      'getAnonymizedData',
    ]),
    ...mapGetters('users', ['isDownloadBlocked']),

    isTcCompleted() {
      return this.actionCompletedInfo?.isTcCompleted;
    },
    shouldToggleDisable() {
      return (
        this.getSidePanelAction === 'replaceVisuals' ||
        this.getSidePanelAction === 'convertTemplate'
      );
    },
    isSuggestionsLoaded() {
      return this.complyDetails?.step === 1;
    },

    toggleBestPracticesSwitch: {
      get() {
        return this.$store.state.slidesStore.toggleBestPracticesSwitch;
      },

      set(value) {
        this.$store.dispatch('slidesStore/setToggleBestPracticesSwitch', value);
      },
    },

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },

    showBpToggleSwitch() {
      return (
        [
          'userUploadedSlide',
          'userGeneratedSlide',
          'synthesisSlide',
          'userRedesignSlide',
          'userComplySlide',
        ].includes(this.slideType) || this.isBPLSlideView
      );
    },

    hideShareIcon() {
      return (
        this.slideType === 'prezentSourcedSlide' ||
        this.slideType === 'companyBrandedSlide' ||
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false) ||
        this.result.permission === 'Restricted'
      );
    },

    hideCopyLink() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },
  },
  methods: {
    ...mapActions('slidesStore', ['setToggleBestPracticesSwitch']),
    getFilename,
    uuidv4,

    getTooltipContentForBestPractisesToggle() {
      const bestPractises = this.getBestPractisesOnSlide.filter(
        (item) => item.isAdded,
      );
      if (bestPractises.length <= 0) {
        return this.$t('slideDetails.noBestPracticesAvailabledOnSlide');
      }
      if (bestPractises.length === 1) {
        return this.$t('slideDetails.oneBestPracticeAvailable');
      }
      return this.$t('slideDetails.bestPracticesAvailable', {
        count: bestPractises.length,
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.slideQuickActionsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  .bestPracticesSwitch {
    padding: 0px 0px 4px 4px;
    .v-input {
      margin: 0;
      padding: 0;
    }
  }
  .v-btn {
    padding: 0;
    width: 28px;
    min-width: 28px;
    max-width: 28px;
    max-height: 28px;
    &:before {
      background-color: #ffffff;
    }
    &:hover {
      background-color: #21a7e01c;
      border-radius: 20px;
    }
  }

  .downloadSlide {
    width: 28px;
    height: 28px;
    display: flex;
    .v-progress-circular {
      margin: 4px auto;
    }
    ::v-deep .icon {
      padding: 0 !important;
      .save-to-gdrive-disabled {
        .download-icon {
          width: 24px;
          height: 28px;
          display: flex;
          .v-icon {
            color: black !important;
            &:after {
              background-color: #ffffff !important;
            }
          }
        }
      }
      .downloadPreferences {
        .downloadEleWithPrefs {
          .download-icon {
            width: 24px;
            height: 28px;
            display: flex;
            .v-icon {
              color: black !important;
              &:after {
                background-color: #ffffff !important;
              }
            }
          }
        }
        .alwaysAskPref {
          .new-slide-detail-speed {
            .download-icon {
              width: 24px;
              height: 28px;
              display: flex;
              .v-icon {
                color: black !important;
                &:after {
                  background-color: #ffffff !important;
                }
              }
            }
            .v-speed-dial__list {
              .download-list {
                top: 0 !important;
                right: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .copy-link,
  .copy-link-disabled {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 28px;
    cursor: pointer;
    .copyLink {
      cursor: pointer;
      position: relative;
      .copyIconAndTitle {
        .icon {
          display: flex;
          flex-direction: column;
        }
      }

      .copiedStatus {
        color: #21a7e0;
        position: absolute;
        margin: 0 auto;
        font-size: 12px;
        display: flex;
        align-items: center;
        transform: translateX(-50%);
        min-width: 70px;

        .v-icon {
          font-size: 12px !important;
          width: 20px;
        }
      }
      .bg-copied-text {
        background: white;
      }
    }
    &:hover {
      background-color: #21a7e01c;
      border-radius: 50%;
    }
  }
  .copy-link-disabled {
    pointer-events: none;
    .copyLink {
      .copyIconAndTitle {
        .icon {
          .v-icon {
            color: #bdbdbd !important;
          }
        }
      }
    }
  }
}
</style>
