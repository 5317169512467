<template>
  <div class="slideActionsContainer">
    <div
      v-for="(action, index) in slideActions"
      :key="index"
      class="actionContainer"
    >
      <NewFeatureBadge
        v-if="action.visible && action.id === 'convertTemplate'"
        module-name="release_4200"
        :feature-id="'convert_template_bpl'"
        :offsetX="'-130'"
        :offsetY="'-15'"
        :size="'small'"
      >
        <v-btn
          rounded
          color="primary"
          text
          @click="
            () => {
              setSidePanelOpen(true);
              handleBtnClick(action.id);
            }
          "
          :disabled="action.disabled"
          :class="action.id === getSidePanelAction ? 'active' : ''"
          v-if="action.visible && action.id !== 'convertTemplate'"
        >
          <div class="main-action-content">
            <component
              :is="getIcon(action)"
              :iconWidth="24"
              :iconHeight="24"
              :iconColor="action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'"
              :iconStrokeWidth="0.5"
              v-if="action.isCustomIcon"
            />
            <v-icon dark size="20" class="slide-action-icon">
              {{ action.icon }}
            </v-icon>
            <span>{{ $t(action.labelText) }}</span>
          </div>
        </v-btn>
      </NewFeatureBadge>
      <v-btn
        rounded
        color="primary"
        text
        @click="
          () => {
            setSidePanelOpen(true);
            handleBtnClick(action.id);
          }
        "
        :disabled="action.disabled"
        :class="action.id === getSidePanelAction ? 'active' : ''"
        :data-pendo-id="action.pendoId"
        v-if="action.visible"
      >
        <div class="main-action-content">
          <component
            :is="getIcon(action)"
            :iconWidth="24"
            :iconHeight="24"
            :iconColor="action.disabled ? 'rgba(0, 0, 0, 0.26)' : '#21a7e0'"
            :iconStrokeWidth="0.5"
            v-if="action.isCustomIcon"
          />
          <v-icon dark size="20" class="slide-action-icon">
            {{ action.icon }}
          </v-icon>
          <span>{{ $t(action.labelText) }}</span>
        </div>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import {
  doesPrefsObjectHasImage,
  doesPrefsObjectHasIcon,
} from '../../../utils';
import NewFeatureBadge from '../../../common/NewFeatureBadge.vue';
import ComplyIcon from '../../../common/Icons/ComplyIcon.vue';
import { trackKnowEvents } from '../../../common/Analytics/KnowEvents';
import {
  TD_CROSS_FEATURE,
  TD_ISDOWNLOAD,
  TD_NUMBEROFSLIDES,
  TD_PREZNAME,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SlideActions',
  data() {
    return {
      iconMap: {
        'comply-icon': ComplyIcon,
      },
    };
  },
  props: {
    slideType: {
      type: String,
      default: '',
    },
    result: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    NewFeatureBadge,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'allowReplaceImageOverall',
      'allowReplaceImageTabs',
      'allowReplaceIconTabs',
      'isPrezComplyFullAccess',
      'isBPESlideComplyFullAccess',
      'isComplyFullAccess',
      'isBPEPrezComplyFullAccess',
    ]),
    ...mapState('prezentationDetails', ['actionCompletedInfo']),
    ...mapGetters('slidesStore', [
      'getSidePanelAction',
      'getIsSlideDetailsDirty',
      'getIsSlideBestPractisesDirty',
    ]),
    ...mapGetters('bestPracticesExamples', ['getSelectedViewType']),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),

    slideActions() {
      return [
        {
          id: 'similarSlides',
          labelText: 'bestPracticesGuide.knowDetails.similarSlides',
          tooltipText: 'bestPracticesGuide.knowDetails.similarSlides',
          icon: 'mdi-view-split-vertical',
          isCustomIcon: false,
          pendoId: 'bpe-slide-action-change-layout',
          disabled: false,
          visible: true,
        },
        {
          id: 'replaceVisuals',
          labelText: 'slideDetails.replaceVisuals',
          tooltipText: 'Replace visuals',
          icon: 'mdi-image-outline',
          isCustomIcon: false,
          pendoId: 'bpe-slide-action-change-visual',
          disabled: this.disableReplaceVisualsBtn,
          visible: this.showReplaceVisual,
        },
        {
          id: 'nodeCountChange',
          labelText: 'slideDetails.editNodeCount',
          tooltipText: 'Edit node count',
          icon: 'mdi-file-tree-outline',
          isCustomIcon: false,
          pendoId: 'bpe-slide-action-change-node-count',
          disabled: false,
          visible: true,
        },
        {
          id: 'convertTemplate',
          labelText: 'Convert template',
          tooltipText: 'Convert template',
          icon: 'comply-icon',
          isCustomIcon: true,
          pendoId: 'bpe-slide-convert-template',
          disabled: false,
          visible: this.showConvertTemplateOption,
        },
      ];
    },

    showConvertTemplateOption() {
      if (
        this.result.permission === 'Restricted' ||
        (this.getSelectedViewType === 'Decks' &&
          !this.isBPEPrezComplyFullAccess) ||
        (this.getSelectedViewType === 'Slides' &&
          !this.isBPESlideComplyFullAccess) ||
        !this.isComplyFullAccess
      ) {
        return false;
      }

      return true;
    },

    disableReplaceVisualsBtn() {
      return (
        (!this.canReplaceImage || this.hideReplaceImage) &&
        (!this.canReplaceIcons || this.hideReplaceIcon)
      );
    },

    canReplaceImage() {
      return doesPrefsObjectHasImage(this.result.prefs);
    },

    hasIconBus() {
      if (this.result.prefs && this.result.prefs.has_icons_bus) {
        if (typeof this.result.prefs.has_icons_bus === 'string') {
          return this.result.prefs.has_icons_bus === 'yes';
        }
        return this.result.prefs.has_icons_bus;
      }
      return false;
    },

    hasIcons() {
      if (this.result.prefs && this.result.prefs.has_icons) {
        if (typeof this.result.prefs.has_icons === 'string') {
          return this.result.prefs.has_icons === 'yes';
        }
        return this.result.prefs.has_icons;
      }
      return false;
    },

    hasIconAuto() {
      if (this.result.prefs && this.result.prefs.has_icon_auto) {
        if (typeof this.result.prefs.has_icon_auto === 'string') {
          return this.result.prefs.has_icon_auto === 'yes';
        }
        return this.result.prefs.has_icon_auto;
      }
      return false;
    },

    canReplaceIcons() {
      return doesPrefsObjectHasIcon(this.result.prefs);
      // return (
      //   (this.slideType === 'prezentSourcedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'companyBrandedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userGeneratedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userUploadedSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userRedesignSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'userComplySlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus) ||
      //   (this.slideType === 'synthesisSlide' &&
      //     this.hasIcons &&
      //     this.hasIconsBus)
      // );
    },

    allowReplaceIconOverall() {
      const repIconOverallFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'replace_icon_overall',
      );
      return repIconOverallFeature && repIconOverallFeature.enabled;
    },

    showReplaceVisual() {
      return this.showReplaceIcon || this.showReplaceImage;
    },

    showReplaceImage() {
      return (
        !this.hideReplaceImage &&
        this.allowReplaceImageOverall &&
        this.allowReplaceImageTabs
      );
    },

    showReplaceIcon() {
      return (
        !this.hideReplaceIcon &&
        this.allowReplaceIconOverall &&
        this.allowReplaceIconTabs
      );
    },

    hideReplaceImage() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    hideReplaceIcon() {
      return (
        ([
          'userUploadedSlide',
          'userGeneratedSlide',
          'userRedesignSlide',
          'userComplySlide',
          'synthesisSlide',
        ].includes(this.slideType) &&
          this.result.isOwner === false &&
          this.result.permission === 'Viewer') ||
        this.result.permission === 'Restricted'
      );
    },

    showNodeCountChangeOption() {
      return ['prezentSourcedSlide', 'companyBrandedSlide'].includes(
        this.slideType,
      );
    },

    showChangeLayoutOption() {
      return ['prezentSourcedSlide', 'companyBrandedSlide'].includes(
        this.slideType,
      );
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setSidePanelAction',
      'setSidePanelOpen',
      'setIsDetailViewInPageAction',
      'setDetailViewInPageAction',
      'setSlideViewConfirmationModalState',
    ]),

    getIcon(action) {
      return this.iconMap[action?.icon];
    },
    handleBtnClick(view) {
      if (
        this.getIsDirty === true ||
        this.getIsDirtyIcon === true ||
        this.getIsSlideDetailsDirty === true ||
        this.getIsSlideBestPractisesDirty === true ||
        this.actionCompletedInfo?.isTcCompleted
      ) {
        // check for dirty flags and show popup
        this.setIsDetailViewInPageAction(true);
        this.setDetailViewInPageAction(view);
        this.setSlideViewConfirmationModalState(true);
      } else {
        this.setSidePanelAction(view);
      }
      if (view === 'nodeCountChange') {
        trackKnowEvents.knowSlidesEditNodeCountClick(
          this.result,
          this.currentUser,
          {
            [TD_ISDOWNLOAD]: 0,
          },
        );
      } else if (view === 'similarSlides') {
        trackKnowEvents.knowSlidesSimilarSlidesClick(
          this.result,
          this.currentUser,
          {
            [TD_ISDOWNLOAD]: 0,
          },
        );
      } else if (view === 'replaceVisuals') {
        trackKnowEvents.knowSlidesReplaceVisualsClick(
          this.result,
          this.currentUser,
          {
            [TD_ISDOWNLOAD]: 0,
          },
        );
      } else if (view === 'convertTemplate') {
        trackKnowEvents.convertTemplateClick(
          this.currentUser,
          {
            [TD_CROSS_FEATURE]: 'Best Practice Library (Slides)',
            [TD_NUMBEROFSLIDES]: 1,
            [TD_PREZNAME]: this.result.name,
          },
          this.result,
        );
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.slideActionsContainer {
  padding: 8px 0px;
  display: flex;
  flex-wrap: wrap;
  .actionContainer {
    .v-btn {
      text-transform: none;
      letter-spacing: normal;
      margin-right: 16px;
      .v-icon {
        padding: 4px 4px 6px 0px;
      }
      &.active::before {
        background-color: #21a7e0;
        opacity: 0.08;
      }
    }
  }

  .main-action-content {
    display: flex;
    align-items: center;

    svg {
      width: 20px !important;
    }
  }
}
</style>
