<template>
  <div class="acc-wrapper">
    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('sideBarFeatures.zenithWorkshop') }}</h2>
          <div class="title-section-subheading">
            <span v-html="$t('zenithWorkshopSteps.titleDesc')"></span>
          </div>
        </div>
      </div>

      <v-img
        class="banner-img"
        src="/assets/img/zenithWorkshop/header-banner.svg"
      />

      <ZenithWorkshopContactUs :placement="'Zenith learning workshops'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('zenithWorkshop.weOffer') }}</h2>
        </div>
      </div>

      <Loading v-if="loading" />

      <template v-else>
        <h2 class="offer-sub-title">Standard</h2>
        <div class="offer-cntr">
          <div
            v-for="(offer, index) in standardOfferings"
            :key="index + '_offer'"
          >
            <v-card class="offer-meta-cntr standard-cntr">
              <v-card-title class="offer-title">
                {{ $t(offer.serviceMetaData.sub_workshop_category) }}
              </v-card-title>
              <v-img class="offer-img" :src="offer.imageUrlS3" contain />

              <v-card
                class="offer-sub-cntr"
                v-for="(subOffer, subInd) in offer.serviceMetaData
                  .sub_workshop_type"
                :key="subInd + '_subOffer'"
              >
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-title v-bind="attrs" v-on="on">
                      {{ $t(subOffer.type) }}
                    </v-card-title>
                  </template>
                  <span>{{ subOffer.tooltip }}</span>
                </v-tooltip>
              </v-card>
            </v-card>
          </div>
        </div>

        <h2 class="mt-6 offer-sub-title">Master Class</h2>
        <div class="offer-cntr">
          <div
            v-for="(offer, index) in masterClassOfferings"
            :key="index + '_offer'"
          >
            <v-card class="offer-meta-cntr master-cntr">
              <v-card-title class="offer-title">
                {{ $t(offer.serviceMetaData.sub_workshop_category) }}
              </v-card-title>
              <v-img class="offer-img" :src="offer.imageUrlS3" contain />

              <v-card
                class="offer-sub-cntr"
                v-for="(subOffer, subInd) in offer.serviceMetaData
                  .sub_workshop_type"
                :key="subInd + '_subOffer'"
              >
                <v-tooltip top max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card-title v-bind="attrs" v-on="on">
                      {{ $t(subOffer.type) }}
                    </v-card-title>
                  </template>
                  <span>{{ subOffer.tooltip }}</span>
                </v-tooltip>
              </v-card>
            </v-card>
          </div>
        </div>
      </template>

      <ZenithWorkshopContactUs
        :placement="'Zenith learning workshops we offer'"
      />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.howItWorks') }}</h2>
        </div>
      </div>

      <div ref="timeline" class="timeline-container">
        <div
          v-for="(content, index) in timelineData"
          :key="index + '_timeline'"
          class="timeline-item"
          :class="{ opposite: index % 2 === 0 }"
        >
          <v-card>
            <v-img
              height="200"
              width="300"
              :src="content.image"
              contain
              class="text-white"
            />
            <v-card-title class="timeline-title">
              {{ $t(content.heading) }}
            </v-card-title>
            <v-card-text class="timeline-desc">
              {{ $t(content.description) }}
            </v-card-text>
          </v-card>
          <div class="dot"></div>
        </div>
        <div
          ref="progress"
          class="progress-bar"
          :style="{
            top: timelineHeight / 6 + 'px',
            height: (timelineHeight / 3) * 2 + 'px',
          }"
        >
          <div
            class="progress-indicator"
            :style="{ height: progress + '%' }"
          ></div>
        </div>
      </div>

      <ZenithWorkshopContactUs :placement="'How it works'" />
    </div>

    <div class="acc-cntr" v-if="sliderImages.length">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('zenithWorkshop.workshops') }}</h2>
        </div>
      </div>
      <div class="container">
        <v-carousel cycle hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item v-for="(slide, index) in sliderImages" :key="index">
            <div class="slider-image-cntr">
              <v-img :src="slide" class="full-height" contain></v-img>
            </div>
          </v-carousel-item>

          <template v-slot:indicators>
            <v-pagination
              v-model="activeSlide"
              :length="sliderImages.length"
              circle
              color="primary"
            />
          </template>
        </v-carousel>
      </div>

      <ZenithWorkshopContactUs :placement="'Our workshops'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('zenithWorkshop.ourCoaches') }}</h2>
        </div>
      </div>

      <div class="experts-cntr">
        <div v-for="(coach, index) in coaches" :key="index + '_coach'">
          <v-card>
            <div class="expert-header">
              <div class="expert-img-cntr">
                <v-img :src="coach.profileImgURL" contain />
              </div>

              <div class="expert-title">
                <v-card-title class="expert-name">{{
                  $t(coach.fullName)
                }}</v-card-title>
                <v-card-subtitle
                  >{{ $t(coach.designation) }},
                  {{ $t(coach.company) }}</v-card-subtitle
                >
              </div>
            </div>
            <v-card-text class="expert-desc">{{
              $t(coach.description)
            }}</v-card-text>
          </v-card>
        </div>
      </div>

      <ZenithWorkshopContactUs :placement="'Our coaches'" />
    </div>

    <div class="acc-cntr" v-if="customerStories.length">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.customerTestimonials') }}</h2>
        </div>
      </div>

      <div class="customer-stats">
        <div v-for="(item, i) in customerStories" :key="i + '_testimonial'">
          <v-card>
            <p class="customer-story-msg">{{ $t(item.testimony) }}</p>
            <p class="customer-story-by">
              - {{ $t(item.designation) }}, {{ item.company }}
            </p>
          </v-card>
        </div>
      </div>

      <ZenithWorkshopContactUs :placement="'Customer testimonials'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.faq') }}</h2>
        </div>
      </div>

      <div class="faq-questions">
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(faq, index) in faqData"
            :key="index"
            @click="handleFAQHandle(faq.question)"
          >
            <v-expansion-panel-header class="faq-header">
              {{ $t(faq.question) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="faq-content"
              v-html="returnMarked($t(faq.answer))"
            >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <ZenithWorkshopContactUs :placement="'FAQ'" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import serviceOperationsApi from '../../../API/service-operations';
import ZenithWorkshopContactUs from './ZenithWorkshopContactUs.vue';
import Loading from '../../common/Loading.vue';
import { getFileURL } from '@/utils/calyrex';
// import { trackAcceleratorsEvents } from '../../common/Analytics/AcceleratorsEvents';
// import { TD_FAQTITLE } from '../../common/Analytics/MatomoTrackingDataHelper';
import EventBus from '../../common/event-bus';

export default {
  name: 'ZenithWorkshopContainer',
  components: { ZenithWorkshopContactUs, Loading },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  data() {
    return {
      loading: true,
      timelineHeight: 0,
      offerings: [],
      standardOfferings: [],
      masterClassOfferings: [],
      coaches: [],
      customerStories: [],
      timelineData: [
        {
          image: '/assets/img/zenithWorkshop/timelines/timeline-1.svg',
          heading: 'zenithWorkshopSteps.stepOneTitle',
          description: 'zenithWorkshopSteps.stepOneDescp',
        },
        {
          image: '/assets/img/zenithWorkshop/timelines/timeline-2.svg',
          heading: 'zenithWorkshopSteps.stepTwoTitle',
          description: 'zenithWorkshopSteps.stepTwoDescp',
        },
        {
          image: '/assets/img/zenithWorkshop/timelines/timeline-3.svg',
          heading: 'zenithWorkshopSteps.stepThreeTitle',
          description: 'zenithWorkshopSteps.stepThreeDescp',
        },
      ],
      sliderImages: [],
      progress: 0,
      faqData: [
        {
          question: 'zenithWorkshopFAQ.question1',
          answer: 'zenithWorkshopFAQ.answer1',
        },
        {
          question: 'zenithWorkshopFAQ.question2',
          answer: 'zenithWorkshopFAQ.answer2',
        },
        {
          question: 'zenithWorkshopFAQ.question3',
          answer: 'zenithWorkshopFAQ.answer3',
        },
        {
          question: 'zenithWorkshopFAQ.question4',
          answer: 'zenithWorkshopFAQ.answer4',
        },
        {
          question: 'zenithWorkshopFAQ.question5',
          answer: 'zenithWorkshopFAQ.answer5',
        },
      ],
      leftNavExpanded: false,
    };
  },
  async beforeMount() {
    if (
      !this.isZenithWorkshopEnabled ||
      this.isZenithWorkshopEnabled === 'no_access'
    ) {
      this.$router.push('/home', () => {});
    }
  },
  async mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });

    if (this.$refs.timeline) {
      const rect = this.$refs.timeline.getBoundingClientRect();
      this.timelineHeight = rect.height;
    }
    this.getExpertServiceData();
  },
  computed: {
    ...mapGetters('users', ['isZenithWorkshopEnabled']),
    ...mapState('users', ['currentUser']),
  },
  methods: {
    async getProfileImage(imageUrl) {
      let s3Url = '';
      if (imageUrl) {
        await getFileURL(null, `public/${imageUrl}`, null, 'storage')
          .then((s3obj) => {
            s3Url = s3obj;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      return s3Url;
    },
    handleFAQHandle(question) {
      console.log(question);
      // trackAcceleratorsEvents.acceleratoeFAQClick(this.currentUser, {
      //   [TD_FAQTITLE]: this.$t(question),
      // });
    },
    async getExpertServiceData() {
      try {
        const resp = await serviceOperationsApi.methods.getExpertServiceData({
          type: 'zenith-workshop',
        });

        // Process Types Data
        const tempOfferings = await Promise.all(
          resp.data.types.map(async (ele) => {
            const url = await this.getProfileImage(ele.imageUrl);
            ele.imageUrlS3 = url.replace(/"/g, "'");
            return ele;
          }),
        );

        const [standardOfferings, masterClassOfferings] = await Promise.all([
          Promise.all(
            tempOfferings.filter((item) => item.value === 'Standard'),
          ),
          Promise.all(
            tempOfferings.filter((item) => item.value === 'Master Class'),
          ),
        ]);
        standardOfferings.forEach((offer) => {
          offer.serviceMetaData.sub_workshop_type.sort(
            (a, b) => a.typeOrder - b.typeOrder,
          );
        });
        masterClassOfferings.forEach((offer) => {
          offer.serviceMetaData.sub_workshop_type.sort(
            (a, b) => a.typeOrder - b.typeOrder,
          );
        });

        this.standardOfferings = standardOfferings;
        this.masterClassOfferings = masterClassOfferings;

        // List of Coaches
        const coaches = await Promise.all(
          resp.data.experts.map(async (ele) => {
            const url = await this.getProfileImage(ele.profileImg);
            ele.profileImgURL = url.replace(/"/g, "'");
            return ele;
          }),
        );
        this.coaches = coaches;

        // Process Customer Stories
        const customerStories = await Promise.all(
          resp?.data?.customerTestimonials || [],
        );
        this.customerStories = customerStories;

        // Process Samples Data
        const sliderImages = await Promise.all(
          resp.data.sampleImages.map(async (ele) => {
            const url = await this.getProfileImage(ele.imageUrl);
            ele.imageUrlS3 = url.replace(/"/g, "'");
            return ele;
          }),
        );
        this.sliderImages = sliderImages.map((ele) => ele.imageUrlS3);

        this.loading = false;
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    onScroll() {
      window.requestAnimationFrame(() => {
        const target = this.$refs.progress.getBoundingClientRect();
        let top = target.top + this.timelineHeight;
        if (!this.loading) {
          top += 1200; // Reassigned top value so that the top value considers the adjusted height of the previous 'offer-cntr' after it is registered on the viewport
        }
        const itemLength =
          // eslint-disable-next-line no-nested-ternary
          window.scrollY + window.innerHeight <= top
            ? 0
            : window.scrollY + window.innerHeight - top <=
              window.innerHeight + target.height
            ? window.scrollY + window.innerHeight - top
            : window.innerHeight + target.height;
        const percentage = Math.round(
          (itemLength * 100) / (window.innerHeight + target.height),
        );
        if (percentage <= 100) {
          this.progress = percentage;
        }
      });
    },
    returnMarked(content) {
      return DOMPurify.sanitize(
        `<div class="marked-container">${marked(content)}</div>`,
      );
    },
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    window.removeEventListener('scroll', this.onScroll);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.acc-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
}
.slider-image-cntr {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensures images take up full space */
}

.full-height {
  height: 100%;
  width: auto;
  max-height: 100%;
}
.acc-cntr {
  margin: auto;
  margin-top: 15px;
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .spinning-loading-wrapper {
    position: unset;
    height: fit-content;
  }

  .header-section {
    margin: 20px 0px 20px 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title-section-subheading {
      font-size: 16px;
    }
    .red-color {
      font-weight: 700;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.01em;
      color: #000000;
      margin-bottom: 8px;
    }
  }

  .banner-img {
    width: 90%;
    margin-bottom: 1rem;
  }

  .v-carousel {
    height: 595px !important;

    ::v-deep .v-window__container {
      height: 550px;
    }

    ::v-deep .v-window__prev,
    ::v-deep .v-window__next {
      margin: 0;
    }
  }

  .slider-image-cntr {
    display: grid;
    place-items: center;
  }

  .submit-req-btn {
    display: grid;
    place-items: center;
    margin: 1rem 0;
  }
}

.offer-sub-title {
  width: 100%;
  margin-bottom: 1.5rem;
  color: #21a7e0;
  font-size: 24px;
  font-weight: 600;
}

.offer-cntr {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-around;
  max-width: 100%;
  margin-bottom: 1rem;

  .v-card {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    .v-card__title {
      line-height: 1.5rem;
      padding: 8px 16px;
      height: 70px;
    }

    .offer-img {
      height: 100px;
    }

    .offer-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      text-align: center;
      color: #000000;
      font-size: 20px;
      padding: 0 1rem 1rem 1rem;
    }
  }
}

.offer-meta-cntr {
  padding: 1.5rem;
}

.standard-cntr {
  height: 510px;
}

.master-cntr {
  height: 550px;
}

.offer-sub-cntr {
  margin-top: 1rem;

  .v-card__title {
    font-size: 1rem;
    font-weight: 400;
    width: 100%;
    word-break: break-word;
  }
}

.timeline-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .timeline-item {
    position: relative;
    width: 50%;

    .dot {
      height: 36px;
      width: 36px;
      position: absolute;
      top: 50%;
      left: 50%;
      border: 2px solid #21a7e0;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      z-index: 3;
    }
  }

  .v-card {
    transform: translateX(calc(50% + 40px));
  }

  .opposite {
    .v-card {
      transform: translateX(calc(-50% - 40px));
    }
  }

  .progress-bar {
    width: 7px;
    background: #d8d8d8;
    position: absolute;

    .progress-indicator {
      width: 100%;
      background: #21a7e0;
    }
  }
  .v-card {
    max-width: 535px;
    width: calc(100% - 40px);
    margin: auto;
    height: 350px;
    padding: 10px;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .timeline-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      color: #000000;
    }
    .timeline-desc {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      text-align: center;
      color: #697077;
    }
  }
}

.experts-cntr {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.5rem;
  overflow-x: scroll;
  width: 100%;
  max-width: fit-content;

  .v-card {
    width: 300px;
    height: 100%;
    overflow: hidden;
    border-radius: 12px;

    .expert-header {
      position: relative;

      .expert-img-cntr {
        height: 300px;
        overflow: hidden;
      }

      .v-img {
        width: 100%;
      }

      .expert-title {
        position: absolute;
        bottom: 0;
        background-color: #00000076;
        color: white;
        width: 100%;
        text-align: center;
        font-family: Lato;
        line-height: 24px;

        .expert-name {
          display: block;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
    }

    .expert-desc {
      font-family: Lato;
      font-size: 1rem;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: #000000;
    }
  }
}

.customer-stats {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.5rem;
  overflow-x: scroll;
  width: 100%;
  max-width: fit-content;

  .v-card {
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    gap: 8px;
    min-height: 175px;
    width: 300px;
    height: 100%;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    h3 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;

      text-align: center;
    }
    .customer-story-by {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: end;
      color: black;
    }
    .customer-story-msg {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: flex-end;
      text-align: start;
      color: #697077;
    }
  }
}

.faq-questions {
  margin: 0px 0px 20px 0px;
  .faq-content {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #697077;
  }
  .faq-header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #000000;
  }
  ::v-deep .v-expansion-panel {
    box-shadow: $item-box-shadow;
    border-radius: 8px;
    margin-top: 16px;
    padding: 24px 16px;

    &::before {
      box-shadow: none !important;
    }
    &::after {
      border: 0;
    }

    .v-expansion-panel-header {
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
    }

    .v-expansion-panel-content {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin: 1em 24px;
      .marked-container {
        ol {
          margin-bottom: 1em;
        }
        ul {
          margin-bottom: 1em;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
